import {createRoot, Root} from "react-dom/client";
const createSvgElement = (tag: string, attributes: Record<string, string>) => {
  const element = document.createElementNS("http://www.w3.org/2000/svg", tag);
  for (const [key, value] of Object.entries(attributes)) {
    element.setAttribute(key, value);
  }
  return element;
};
const createVector = () => {
  // Создаем SVG элемент
  const svg = createSvgElement("svg", {
    "width": "100%",
    "viewBox": "0 0 1512 217",
    "fill": "none"
  });

  // Создаем первый path
  const path1 = createSvgElement("path", {
    "d": "M836.599 80.2832L932.502 72.6372L1052.89 37.2743L1232.45 126.159L1422.22 104.177L1512 0V216H0L420.34 195.929L669.279 155.788L769.263 161.522L820.275 126.159L836.599 80.2832Z",
    "fill": "var(--stels-color-gray-dark)",
    "fill-opacity": "0.7"
  });
  svg.appendChild(path1);

  // Создаем второй path
  const path2 = createSvgElement("path", {
    "d": "M1130.67 141.366L1157.99 78.3106L1227.23 150.812L1299.24 74.3084L1432.26 96.2997L1512 40V217H877L995.71 150.812L1010.57 125.736L1066.56 129.007L1095.13 108.838L1130.67 141.366Z",
    "fill": "var(--stels-color-gray-dark)",
    "fill-opacity": "0.8"
  });
  svg.appendChild(path2);

  // Создаем третий path
  const path3 = createSvgElement("path", {
    "d": "M1037.85 124.273L1071.81 46.9683L1133.49 79.3436L1151.81 51.6211L1233.21 113.758L1294.26 70.7401L1412.85 69.0228L1512 0V217H439.559L769.228 190.233L870.03 135.854L888.503 105.112L958.131 109.122L993.656 84.3941L1037.85 124.273Z",
    "fill": "var(--stels-color-gray-dark)",
    "fill-opacity": "0.6"
  });
  svg.appendChild(path3);
  return svg
};

export default function createRootComponent(window: Window): Root {
  try {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.document.addEventListener('wheel', (e: WheelEvent) => {
        if (e.deltaMode !== 1) {
          e.preventDefault();
        }
      }, {passive: false});
    }
    window.document.addEventListener('wheel', (event: WheelEvent) => {
      if (event.deltaMode > 1) {
        event.preventDefault();
      }
    }, false);

    const root = document.createElement('div');
    const overly = document.createElement('div');
    const svg = createVector();

    overly.appendChild(svg);

    overly.className = "stels-overly";
    root.className = 'stels'
    document.body.appendChild(root);
    document.body.appendChild(overly);
    document.body.style.overflow = "hidden"

    return createRoot(root);
  } catch (e) {
    console.error(e);
    throw new Error("Failed to create Component Root");
  }
}
