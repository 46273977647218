import React from "react"
import "@styles/index.css"
import createRootComponent from "@utils/createRootComponent";
import Graphite from "@components/Craphite/Graphite";

createRootComponent(window).render(
  <div className="--stels-event-modal">
    <div className="--stels-event-logo">
      <Graphite size={12}/>
    </div>
    <div className="--stels-event-title">
      <div><span>STELS</span></div>
    </div>
    <div className="--stels-event-information">
      <span>Innovative control Markets</span>
    </div>
    <h1>
      Under Construction
    </h1>
    <div className="--stels-event-copy">
      <span>© 2023 GLIESEREUM LAB</span>
    </div>
  </div>
)



